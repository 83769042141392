import { Params, RouterStateSnapshot } from "@angular/router";
import { RouterStateSerializer } from "@ngxs/router-plugin";

export interface RouterStateParams {
  url: string;
  params: Params;
  queryParams: Params;
  datas: { [key: string]: any };
}


export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateParams> {
  serialize(routerState: RouterStateSnapshot): RouterStateParams {
    const {
      url,
      root: { queryParams },
    } = routerState;

    let { root: route } = routerState;
    let datas = { ...routerState.root.data };

    while (route.firstChild) {
      route = route.firstChild;
      datas = { ...datas, ...route.data };
    }

    const { params } = route;

    return { url, params, queryParams, datas };
  }
}

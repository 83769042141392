import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root', deps: [HttpClient],  })
export class EnvService {
  #http = inject(HttpClient);
  #env: Observable<Record<string, string>> = this.#http.get<Record<string, string>>('/assets/env.json').pipe(map((env) => env));

  getEnv(key: string) {
    return this.#env.pipe(map((env) => env[key]));
  }

}

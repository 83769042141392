import { Component, inject, OnInit } from '@angular/core';
import { AuthService, FrontAuthModule } from '@beathletics/auth';
import { MetaService } from './shared/meta.service';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { RouterNavigation } from '@ngxs/router-plugin';
import { provideRouter, RouterModule, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';

@Component({
  selector: 'beathletics-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterModule, FrontAuthModule],
})
export class AppComponent implements OnInit {
  private actions$ = inject(Actions);
  private authService = inject(AuthService);
  private metaService = inject(MetaService);
  private transloco = inject(TranslocoService);

  profile = this.authService.getProfile();
  roles = this.authService.getRoles();

  logout() {
    this.authService.logout();
  }

  ngOnInit() {
    this.transloco.selectTranslation().subscribe();
    this.actions$.pipe(ofActionSuccessful(RouterNavigation)).subscribe(() => this.metaService.setBasicMetaTags());
  }
}
